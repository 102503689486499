import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { IExposedGameOfLiveMethods } from './App';

interface IProps {
    gridSize: number,
    simulationRunning: boolean,
    interval: number
}

export const GameOfLive = forwardRef<IExposedGameOfLiveMethods, IProps>((props, ref) => {
    const interval = useRef<(() => void) | null>(null);
    const [grid, setGrid] = useState<boolean[][]>([]);
    let [count, setCount] = useState(0);
    const [lastHoveredInformation, setLastHoveredInformation] = useState({
        cell: -1,
        row: -1
    });

    useImperativeHandle(ref, () => ({
        resetField() {
            const newGrid: boolean[][] = [];

            for (let i = 0; i < props.gridSize; i++) {
                newGrid.push(Array(props.gridSize).fill(false))
            }

            setGrid(newGrid);
        }
    }))

    interval.current = () => {
        if (!props.simulationRunning)
            return;

        setCount(count + 1);
        setGrid(prev => {
            const newGrid: boolean[][] = [];

            for (let i = 0; i < prev.length; i++) {
                newGrid.push(Array(props.gridSize));
    
                for (let j = 0; j < prev[i].length; j++) {
                    const left = prev[i][j - 1];
                    const topLeft = i < 1 ? false : prev[i - 1][j - 1];
                    const top = i < 1 ? false : prev[i - 1][j];
                    const topRight = i < 1 ? false : prev[i - 1][j + 1];
                    const right = prev[i][j + 1];
                    const bottomRight = i >= prev.length - 1 ? false : prev[i + 1][j + 1];
                    const bottom = i >= prev.length - 1 ? false : prev[i + 1][j];
                    const bottomLeft = i >= prev.length - 1 ? false : prev[i + 1][j - 1];
    
                    const aliveNeighboursCount = [left, topLeft, top, topRight, right, bottomRight, bottom, bottomLeft].filter(n => n === true).length;
    
                    if ((aliveNeighboursCount === 2 || aliveNeighboursCount === 3) && prev[i][j] === true)
                        newGrid[i][j] = true;
                    else if (aliveNeighboursCount === 3)
                        newGrid[i][j] = true;
                    else
                        newGrid[i][j] = false;
                }
            }

            return newGrid;
        });
    }

    useEffect(() => {
        setGrid(prev => {
            const newGrid: boolean[][] = [];

            for (let i = 0; i < props.gridSize; i++) {
                newGrid.push(Array(props.gridSize).fill(false))
            }
    
            for (let i = 0; i < newGrid.length; i++) {
                for (let j = 0; j < newGrid[i].length; j++) {
                    if (prev[i] === undefined || prev[i][j] === undefined)
                        continue;
    
                    newGrid[i][j] = prev[i][j]
                }
            }

            return newGrid;
        });

    }, [props.gridSize])

    useEffect(() => {
        const intervalReference = setInterval(() => {
            if (interval.current)
                interval.current();
        }, props.interval);

        return () => {
            clearInterval(intervalReference);
        }

    }, [props.simulationRunning, props.interval])

    const flipCellStage = (row: number, cell: number) => {
        setGrid(prev => {
            const newGrid: boolean[][] = [];

            for (let i = 0; i < props.gridSize; i++) {
                newGrid.push(Array(props.gridSize).fill(false))
            }
    
            for (let i = 0; i < newGrid.length; i++) {
                for (let j = 0; j < newGrid[i].length; j++) {
                    if (grid[i] === undefined || prev[i][j] === undefined)
                        continue;
    
                    newGrid[i][j] = prev[i][j]
                }
            }
    
            newGrid[row][cell] = !prev[row][cell];

            return newGrid;
        })
    }

    const flipCellStageHover = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, row: number, cell: number) => {
        if (evt.buttons !== 1 || (lastHoveredInformation.cell === cell && lastHoveredInformation.row === row))
            return;

        setLastHoveredInformation({ cell, row });
        flipCellStage(row, cell);
    }

    return (
        <>
            <div>
                Step: {count}
            </div>
            <div>
                {grid.map((row, rowIndex) => (
                    <div className='row' key={rowIndex}>
                        {row.map((alive, cellIndex) => (
                            <div
                                onClick={() => flipCellStage(rowIndex, cellIndex)}
                                onMouseMove={(e) => flipCellStageHover(e, rowIndex, cellIndex)}
                                className={`cell ${alive ? 'alive' : 'dead'}`}
                                key={cellIndex}>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
});