import React, { useRef, useState } from 'react';
import './App.css';
import { GameOfLive } from './GameOfLive';

export interface IExposedGameOfLiveMethods {
  resetField(): void;
}

function App() {

  const [gridSize, setGridSize] = useState(3);
  const [interval, setInterval] = useState(500)
  const [simultaionRunning, setSimulationRunning] = useState(false);
  const GameOfLiveRef = useRef<IExposedGameOfLiveMethods>(null);

  return (
    <>
      <div className='center'>
        <div className="gridSizeInput">
          <div className='inputGroup'>
            <label htmlFor="gridsize">Grid size: ({gridSize} x {gridSize})</label>
            <input id="gridsize" value={gridSize} type='range' min='3' max='50' onChange={(e) => setGridSize(e.currentTarget.valueAsNumber || 3)} />
          </div>

          <div className='inputGroup'>
            <label htmlFor="interval">Interval MS: ({interval})</label>
            <input id="interval" value={interval} type='range' min='100' max='1000' onChange={(e) => setInterval(e.currentTarget.valueAsNumber || 500)} />
          </div>

          <button onClick={() => setSimulationRunning(prev => !prev)}>
            {simultaionRunning ? "Stop" : "Start"} simulation
          </button>

          <button onClick={() => GameOfLiveRef.current?.resetField()}>
            Reset grid
          </button>

        </div>
        <div>
          {
            gridSize < 1
              ? "GridSize must be bigger then 1"
              : <GameOfLive ref={GameOfLiveRef} gridSize={gridSize} simulationRunning={simultaionRunning} interval={interval} />
          }
        </div>
      </div>
    </>
  );
}

export default App;
